import { useState, useRef, forwardRef } from 'react';

import { ActionIcon, Group, Stack, Text, TextInput } from '@mantine/core';

import { IconX } from '@tabler/icons-react';

import type { InstantAnalysisQuestion } from '../../../entities/InstantAnalysisQuestion';

type QuestionsInfiniteInputProps<T extends string | InstantAnalysisQuestion> = {
  value?: T[];
  error?: string;
  onAddQuestion: (question: string) => void;
  onDeleteQuestion: (question: string) => void;
};

/**
 * `QuestionsInfiniteInput` is a React component for dynamically managing a list of questions.
 * It allows users to add new questions and delete existing ones.
 * Usage involves providing `value` (array of questions), `onAddQuestion` (function for adding questions),
 * and `onDeleteQuestion` (function for deleting questions). It includes input handling and error display.
 */
export function QuestionsInfiniteInput<T extends string | InstantAnalysisQuestion>({
  value = [],
  error,
  onAddQuestion,
  onDeleteQuestion,
}: QuestionsInfiniteInputProps<T>) {
  const [newQuestionValue, setNewQuestionValue] = useState<string | undefined>();
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <Stack spacing={4}>
      <Text variant="sm" fw={700}>
        Questions
      </Text>
      <Stack spacing="md">
        {value.map((question, id) => {
          const questionValue = typeof question === 'string' ? question : question.question;
          return (
            <QuestionDisplay
              key={`${id}`}
              question={questionValue}
              onDeleteQuestion={() => onDeleteQuestion(questionValue)}
            />
          );
        })}
        <QuestionInput
          ref={inputRef}
          isError={!!error}
          value={newQuestionValue}
          setValue={setNewQuestionValue}
          onSave={() => {
            newQuestionValue && onAddQuestion(newQuestionValue);
            setNewQuestionValue('');
            inputRef.current?.focus();
          }}
        />
        {newQuestionValue && <QuestionInput />}
      </Stack>
      {error && (
        <Text variant="sm" fw={400} c="red.5">
          {error}
        </Text>
      )}
    </Stack>
  );
}

type QuestionDisplayProps = {
  question: string;
  onDeleteQuestion: (question: string) => void;
};

const QuestionDisplay = ({ question, onDeleteQuestion }: QuestionDisplayProps) => {
  return (
    <Group
      noWrap
      position="apart"
      px="xs"
      sx={theme => ({
        border: `1px solid ${theme.colors.gray[1]}`,
        borderRadius: theme.radius.md,
      })}
    >
      <Text key={question} variant="sm" fw="400" c="gray.9" p="xs">
        {question}
      </Text>
      <ActionIcon variant="subtle" onClick={() => onDeleteQuestion(question)}>
        <IconX size={16} />
      </ActionIcon>
    </Group>
  );
};

type QuestionInputProps = {
  value?: string;
  isError?: boolean;
  setValue?: (value: string) => void;
  onSave?: () => void;
};

const QuestionInput = forwardRef<HTMLInputElement, QuestionInputProps>(
  ({ value = '', setValue, onSave, isError }: QuestionInputProps, ref) => {
    return (
      <TextInput
        ref={ref}
        value={value}
        onChange={e => setValue && setValue(e.currentTarget.value)}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
            onSave && onSave();
          }
        }}
        onBlur={onSave}
        color="gray.3"
        placeholder="Est-ce que cet appel d'offre correspond à mon activité sachant que je fais ... ?"
        styles={theme => ({
          input: {
            padding: theme.spacing.sm,
            paddingTop: theme.spacing.lg,
            paddingBottom: theme.spacing.lg,
            '::placeholder': {
              color: isError ? theme.colors.red[5] : theme.colors.gray[3],
            },
            color: theme.colors.gray[9],
            border: isError
              ? `1px solid ${theme.colors.red[5]}`
              : `1px solid ${theme.colors.gray[1]}`,
            borderRadius: theme.radius.md,
          },
        })}
      />
    );
  },
);
