import type { ReactNode } from 'react';

import type { HoverCardProps, Sx } from '@mantine/core';
import { HoverCard, Text } from '@mantine/core';
import type { HoverCardDropdownProps } from '@mantine/core';

export type TooltipProps = {
  content: string | ReactNode;
  isVisibile?: boolean;
  children: ReactNode;
  dropDownProps?: HoverCardDropdownProps & { sx?: Sx };
  sx?: Sx;
} & HoverCardProps;

export function Tooltip({
  content,
  children,
  isVisibile = true,
  dropDownProps,
  ...rest
}: TooltipProps) {
  return (
    <HoverCard withinPortal position="bottom" openDelay={400} {...rest}>
      <HoverCard.Target>{children}</HoverCard.Target>
      <HoverCard.Dropdown
        bg="gray.9"
        c="white"
        px="02"
        py="01"
        sx={theme => ({
          border: `1px solid ${theme.colors.gray[8]}`,
          visibility: isVisibile ? 'visible' : 'hidden',
          borderRadius: theme.radius.md,
          boxShadow: theme.shadows.md,
        })}
        {...dropDownProps}
      >
        {typeof content === 'string' ? (
          <Text variant="xs" fw="500" c="white">
            {content}
          </Text>
        ) : (
          content
        )}
      </HoverCard.Dropdown>
    </HoverCard>
  );
}
