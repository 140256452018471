import React from 'react';

import { Box, RingProgress, useMantineTheme } from '@mantine/core';

type AssessmentRecap = {
  blockingPoint: number;
  attentionPoint: number;
  positivePoint: number;
  neutralPoint: number;
  unAssessed: number;
};

type SizeProp = 'xs' | 'lg';

type AssessmentCircularGraphProps = {
  assessmentRecap: AssessmentRecap;
  size?: SizeProp;
  icon?: React.ReactElement;
};

export function AssessmentCircularGraph({
  assessmentRecap,
  size = 'lg',
  icon,
}: AssessmentCircularGraphProps) {
  const theme = useMantineTheme();
  const sizeParam = getSizeParam()[size];
  const total =
    assessmentRecap.blockingPoint +
    assessmentRecap.attentionPoint +
    assessmentRecap.positivePoint +
    assessmentRecap.neutralPoint +
    assessmentRecap.unAssessed;

  const values = [
    {
      value: calculatePercentage(assessmentRecap.positivePoint, total),
      color: theme.colors.green[4],
    },
    {
      value: calculatePercentage(assessmentRecap.attentionPoint, total),
      color: theme.colors.orange[3],
    },
    {
      value: calculatePercentage(assessmentRecap.blockingPoint, total),
      color: theme.colors.red[4],
    },
        {
      value: calculatePercentage(assessmentRecap.neutralPoint, total),
      color: theme.colors.indigo[5],
    },
    {
      value: calculatePercentage(assessmentRecap.unAssessed, total),
      color: theme.colors.gray[1],
    },
  ];
  const gap = { value: sizeParam.gap, color: 'white' };

  const insertGapsAndAdjustValues = (
    values: { value: number; color: string }[],
    gap: { value: number; color: string },
    totalGapSize: number,
  ) => {
    const totalValueWithoutGaps = values.reduce((acc, { value }) => acc + value, 0);

    const adjustedValues = values.map(segment => ({
      ...segment,
      value: calculatePercentage(segment.value, totalValueWithoutGaps, totalGapSize),
    }));

    const result: { value: number; color: string }[] = [];
    adjustedValues.forEach(value => {
      result.push(value);
      if (value.value !== 0) {
        result.push(gap);
      }
    });

    return result;
  };

  const totalGaps = values.filter(v => v.value !== 0).length - 1;
  const totalGapSize = totalGaps * gap.value;
  const valueWithGap = insertGapsAndAdjustValues(values, gap, totalGapSize);

  return (
    <Box w={sizeParam.containerSize} h={sizeParam.containerSize} pos="relative">
      <Box
        pos="absolute"
        w={sizeParam.containerSize}
        h={sizeParam.containerSize}
        ml={sizeParam.margin}
        mt={sizeParam.margin}
        bg="white"
        sx={theme => ({
          border: `1px solid ${theme.colors.gray[1]}`,
          borderRadius: `100%`,
        })}
      />
      <RingProgress
        pos="absolute"
        size={sizeParam.graphSize}
        sections={valueWithGap}
        thickness={sizeParam.thickness}
        label={icon}
      />
    </Box>
  );
}

type SizeParams = {
  graphSize: number;
  containerSize: number;
  margin: number;
  thickness: number;
  gap: number;
};

const getSizeParam = (): Record<SizeProp, SizeParams> => ({
  xs: {
    graphSize: 32,
    containerSize: 30,
    margin: 1,
    thickness: 4,
    gap: 2.5,
  },
  lg: {
    graphSize: 82,
    containerSize: 76,
    margin: 3,
    thickness: 6,
    gap: 0.75,
  },
});

const calculatePercentage = (point: number, total: number, offset = 0) => {
  if (total === 0) {
    return 0;
  }
  return (point / total) * (100 - offset);
};
