import { Stack, Text, Group, Button, Title, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import type { ContextModalProps } from '@mantine/modals';

type CreateStreamSectionProps = {
  onCreate: (title: string) => Promise<boolean>;
};

export const CreateStreamSectionModal = ({
  context,
  id,
  innerProps,
}: ContextModalProps<CreateStreamSectionProps>) => {
  const { onCreate: createStreamSection } = innerProps;
  const form = useForm({
    initialValues: {
      title: '',
    },
    validate: {
      title: (value: string) => (value.trim().length > 0 ? null : 'Champ requis'),
    },
  });

  return (
    <form>
      <Stack py="xxs" px="xs" spacing="md">
        <Stack spacing="xxs">
          <Title order={5}>Créer une nouvelle section</Title>
          <Text variant="sm" fw="400" c="gray.6">
            Organisez vos flux au sein de sections. Saisissez simplement un nom de section pour la
            créer.
          </Text>
        </Stack>
        <TextInput placeholder="Nom de la section..." {...form.getInputProps('title')} />
        <Group position="right">
          <Button
            id="buttonSkip"
            variant="subtle"
            c="primary.9"
            onClick={() => {
              context.closeModal(id);
            }}
          >
            Annuler
          </Button>
          <Button
            maw="25%"
            miw="200px"
            radius="md"
            size="md"
            color="primary.7"
            type="submit"
            onClick={async e => {
              e.preventDefault();
              if (!form.validate().hasErrors) {
                const res = await createStreamSection(form.values.title);
                if (res) {
                  context.closeModal(id);
                }
              }
            }}
          >
            Créer la section
          </Button>
        </Group>
      </Stack>
    </form>
  );
};
